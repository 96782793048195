<template>
    <div id="signup-container">
       <keep-alive>
        <component :is="component"></component>
       </keep-alive>
    </div>
</template>

<script>
import SignupPage from '../../components/onboarding/signup/signup-1.vue'
import CardDetails from '../../components/onboarding/signup/card-details.vue'
import ThankyouMessage from '../../components/onboarding/signup/Thank-you.vue'
export default {
    components:{SignupPage,CardDetails,ThankyouMessage},
    computed:{
        component(){
            let page = this.$store.getters['user/getSignupPage']
            if(page == 0) return 'SignupPage'
            else if(page==1) return 'CardDetails'
            else if(page==2) return 'ThankyouMessage'
            else return 'SignupPage'
        }
    }
}
</script>

<style scoped>

</style>