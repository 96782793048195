<template>
    <div id="container">
    <img :src="require('../../assets/images/landing-desktop.png')" alt="Eat Express" id="desktop-image">
    <img :src="require('../../assets/images/landing-mobile.png')" alt="Eat Express" id="mobile-image">
    <div id="content">
     <h2>EatExpress</h2>
     <h3>Food Delivery Service</h3>
    </div>
    <signup-button @click="redirect('signup')" Text="Sign Up"></signup-button>
    <login-button @click="redirect('login')" Text="Log In"></login-button>
    
    </div>
</template>

<script>
import LoginButton from '../../components/general/submit-alternate.vue'
import SignupButton from '../../components/general/submit-button.vue'
export default {
    components:{LoginButton,SignupButton},
    methods:{
        redirect(value){
            this.$router.push({name:value})
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Libre+Bodoni&family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#container{
    width:100vw;
    height: 100vh;
    overflow-x:hidden;
    overflow-y:auto ;
    position: fixed;
    top:0;
    left:0;
    padding-bottom:3vh;

}
#container::-webkit-scrollbar{
    display: none;
}
@media only screen and (max-width:480px){
#desktop-image{
    display: none;
}
#mobile-image{
    margin:0px;
    padding:0px;
    width:100vw;
}
#content{
    margin-top:0px;
}
#content h2{
    color: #5C5C8B;
font-family: Libre Bodoni;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
text-align: center;
margin-top:-55px;
margin-bottom:8px;
}
#content h3{
    color: #5C5C8B;
text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.2px;
}
}
@media only screen and (min-width:481px) and (max-width:960px){
#mobile-image{
    display: none;
}

#desktop-image{
    width: 100vw;
}
#content{
    margin-top:0px;
}
#content h2{
    color: #5C5C8B;
font-family: Libre Bodoni;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
text-align: center;
margin-top:-50px;
margin-bottom:8px;
}
#content h3{
    color: #5C5C8B;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.2px;
}
}
@media only screen and (min-width:961px){
#mobile-image{
    display: none;
}
#desktop-image{
    width: 100vw;
}
#content{
    margin-top:0px;
}
#content h2{
    color: #5C5C8B;
font-family: Libre Bodoni;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
text-align: center;
margin-top:-145px;
margin-bottom:4px;
}
#content h3{
    color: #5C5C8B;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.2px;
}
}

</style>