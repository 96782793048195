<template>
    <div id="header">
        <notification-card v-if="Notification"></notification-card>
        <div id="row-1" v-else>
            <globe-button></globe-button>
            <img :src="require('../../assets/images/home/eatexpress-mobile.png')" alt="EatExpress" id="eatexpress-mobile">
        </div>
        <div id="row-2">
            <div>
                <h2>Hello Urfan</h2>
            <svg xmlns="http://www.w3.org/2000/svg" width="73" height="6" viewBox="0 0 73 6" fill="none" id="name-arrow">
                <path d="M0.321458 2.94922L2.63086 5.25862L4.94026 2.94922L2.63086 0.639818L0.321458 2.94922ZM72.8052 2.54922H2.63086V3.34922H72.8052V2.54922Z" fill="#25AD34"/>
              </svg>
            </div>
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none" id="profile-mobile">
                <g filter="url(#filter0_d_557_1199)">
                  <circle cx="24.5977" cy="23.0996" r="19.5" fill="#F6F6F6" fill-opacity="0.945098" shape-rendering="crispEdges"/>
                </g>
                <g opacity="0.5">
                  <path d="M24.5452 26.7744C19.9892 26.7744 16.0977 27.4544 16.0977 30.1744C16.0977 32.8954 19.9649 33.5994 24.5452 33.5994C29.1012 33.5994 32.9927 32.9204 32.9927 30.1994C32.9927 27.4784 29.1265 26.7744 24.5452 26.7744Z" fill="#25AD34"/>
                  <path opacity="0.4" d="M24.5452 24.1836C27.6487 24.1836 30.1354 21.8286 30.1354 18.8916C30.1354 15.9546 27.6487 13.5996 24.5452 13.5996C21.4428 13.5996 18.9551 15.9546 18.9551 18.8916C18.9551 21.8286 21.4428 24.1836 24.5452 24.1836Z" fill="#25AD34"/>
                </g>
                <path d="M24.5452 26.7744C19.9892 26.7744 16.0977 27.4544 16.0977 30.1744C16.0977 32.8954 19.9649 33.5994 24.5452 33.5994C29.1012 33.5994 32.9927 32.9204 32.9927 30.1994C32.9927 27.4784 29.1265 26.7744 24.5452 26.7744Z" fill="#25AD34"/>
                <path opacity="0.4" d="M24.5452 24.1836C27.6487 24.1836 30.1354 21.8286 30.1354 18.8916C30.1354 15.9546 27.6487 13.5996 24.5452 13.5996C21.4428 13.5996 18.9551 15.9546 18.9551 18.8916C18.9551 21.8286 21.4428 24.1836 24.5452 24.1836Z" fill="#25AD34"/>
                <defs>
                  <filter id="filter0_d_557_1199" x="0.0976562" y="0.599609" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_557_1199"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_557_1199" result="shape"/>
                  </filter>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110" fill="none" id="profile-tab">
                <g filter="url(#filter0_d_1460_1926)">
                  <circle cx="55" cy="53" r="50" fill="#F6F6F6" fill-opacity="0.945098" shape-rendering="crispEdges"/>
                </g>
                <path d="M55.146 62.2061C42.1234 62.2061 31 64.1781 31 72.066C31 79.9569 42.054 81.9985 55.146 81.9985C68.1687 81.9985 79.2921 80.0294 79.2921 72.1385C79.2921 64.2477 68.2411 62.2061 55.146 62.2061" fill="#25AD34"/>
                <path opacity="0.4" d="M55.1461 54.6935C64.017 54.6935 71.1247 47.8641 71.1247 39.3468C71.1247 30.8295 64.017 24 55.1461 24C46.2781 24 39.1675 30.8295 39.1675 39.3468C39.1675 47.8641 46.2781 54.6935 55.1461 54.6935" fill="#25AD34"/>
                <g opacity="0.5">
                  <path d="M55.146 62.2061C42.1234 62.2061 31 64.1781 31 72.066C31 79.9569 42.054 81.9985 55.146 81.9985C68.1687 81.9985 79.2921 80.0294 79.2921 72.1385C79.2921 64.2477 68.2411 62.2061 55.146 62.2061" fill="#25AD34"/>
                  <path opacity="0.4" d="M55.1461 54.6935C64.017 54.6935 71.1247 47.8641 71.1247 39.3468C71.1247 30.8295 64.017 24 55.1461 24C46.2781 24 39.1675 30.8295 39.1675 39.3468C39.1675 47.8641 46.2781 54.6935 55.1461 54.6935" fill="#25AD34"/>
                </g>
                <defs>
                  <filter id="filter0_d_1460_1926" x="0" y="0" width="110" height="110" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1460_1926"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1460_1926" result="shape"/>
                  </filter>
                </defs>
              </svg>
        </div>
        <div id="row-3">
<menu-button @click="changeScreen(0)" Index="0" Text="Assign Delivery"></menu-button>
<menu-button @click="changeScreen(1)" Index="1" Text="Active Deliveries"></menu-button>
<menu-button @click="changeScreen(2)" Index="2" Text="Past Deliveries"></menu-button>
<menu-button @click="changeScreen(3)" Index="3" Text="Payments"></menu-button>
        </div>
    </div>
</template>

<script>
import GlobeButton from './globe-button.vue'
import MenuButton from './menu-button.vue'
import NotificationCard from '../general/notification-card.vue'
export default {
    components:{GlobeButton,MenuButton,NotificationCard},
    methods:{
        changeScreen(value){
            this.$store.commit('user/changeHomePage',{value:value})
        }
    },
    computed:{
        Notification(){
            return this.$store.getters['user/getNotification']
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#header{
    width:100vw;
    overflow-x:hidden;
    overflow-y:auto ;
    z-index: 1;
}
#header::-webkit-scrollbar{
    display: none;
}
#row-1,
#row-2{
    width:100vw;
}

@media only screen and (max-width:480px){
    #header{
        background-image: url('~@/assets/images/home/mobile-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        padding-bottom:1.5vh;
    }
    #row-1{
        padding-top:3vh;
        padding-left:4vw;
        margin-bottom:7vh;
    }
    #eatexpress-mobile{
         float:right;
         margin-top:-13px;
    }
    #row-2{
        padding-left:4.5vw
    }
    #row-2 h2{
        color: #333;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
float:left
    }
    #name-arrow{
        float:left;
        margin-left:6px;
        margin-top:8px;
    }
    #profile-mobile{
        float:right;
        margin-right:8vw;
        margin-top:-5px;

    }
    #profile-tab{
        display: none;
    }
    #row-3{
        padding-top:2vh;
        padding-left:2vw;
        width:96vw;
        padding-right: 2vw;
        display: grid;
        grid-template-columns: repeat(4,124px);
        overflow-x: auto;
    }
    #row-3::-webkit-scrollbar{
        display: none;
    }
}
@media only screen and (min-width:481px) and (max-width:961px){
    #header{
        background-image: url('~@/assets/images/home/desktop-bg.png');
        background-repeat:no-repeat;
        background-size: cover;
        height:fit-content ;
        padding-bottom: 2vh;
    }
    #row-1{
        padding-top:4vh;
        padding-left:5vw;
        margin-bottom:8vh;
    }
    #eatexpress-mobile{
        float:left;
        margin-top:-11px;
        margin-left:-10px;
    }
    #row-2{
        padding-left:5vw
    }
    #row-2 h2{
        color: #333;
font-family: Inter;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
float:left
    }
    #name-arrow{
        float: left;
     margin-left:8px;
     margin-top:13px   
    }
    #profile-tab{
        float: right;
        margin-right:5vw;
        margin-top:-7vh
    }
    #profile-mobile{
        display: none;
    }
    #row-3{
        padding-top:3vh;
        padding-left:7vw;
        width:90vw;
        padding-right: 3vw;
        display: grid;
        grid-template-columns: repeat(4,20vw);
        gap:2vw
    }
}
@media only screen and (min-width:961px){
    #header{
        background-image: url('~@/assets/images/home/desktop-bg.png');
        background-repeat:repeat-x;
        background-size: cover;
        height: fit-content;
        padding-bottom: 2vh;
    }
    #row-1{
        padding-top:2vh;
        padding-left:2vw;
        margin-bottom:7vh;
    }
    #eatexpress-mobile{
        float:left;
        margin-top:-11px;
        margin-left:-10px;
        cursor: pointer;
    }
    #row-2{
        padding-top:0vh;
        padding-left:2.2vw
    }
    #row-2 h2{
        color: #333;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
float:left
    }
    #name-arrow{
        float: left;
     margin-left:8px;
     margin-top:13px   
    }
    #profile-mobile{
        display: none;
    }
    #profile-tab{
        cursor: pointer;
        float:right;
        margin-right:4.5vw;
        margin-top:-4.5vh;
        width:60px;
        height:60px;
        border-radius: 60px;

    }
    #row-3{
        padding-top:3vh;
        width:100vw;
      display:flex;
      justify-content: center;
    gap:2vw
    }
}
</style>