<template>
    <div id="payment-field">
        <div class="input-case" @click="toggle">
            <input type="text" class="input-1" placeholder="Mode of payment" v-model="option" :disabled="expand">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none" id="dropdown">
            <path d="M2.15625 0.0996094L7.15625 5.09961L12.1562 0.0996098L14.1562 1.09961L7.15625 8.09961L0.15625 1.09961L2.15625 0.0996094Z" fill="#413F3F"/>
          </svg>
          
        </div>
    <div id="payment-options" v-if="ToggleState" :class="ToggleState?'animate':''">
        <div class="payment-option" :class="Option == 'option1'?'payment-active':'payment-passive'" @click="changePayment(0)">
            <h2>{{ option1 }}</h2>
            <h3>{{ detail1 }}</h3>
        </div>
        <div class="payment-option" :class="Option == 'option2'?'payment-active':'payment-passive'" @click="changePayment(1)">
            <h2>{{ option2 }}</h2>
            <h3>{{ detail2 }}</h3>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            option:'',
            option1:"Cash Payment",
            option2:"Online Payment",
            detail1:'(Cash will be collected by the driver)',
            detail2:'(Payment will be collected by the food outlet)',
            expand:false
        }
    },
    methods:{
        changePayment(val){
            this.$store.commit('user/setPaymentMode',{value:val})
            if(val==0)
            this.option = this.option1
            else
            this.option = this.option2
            this.expand=!this.expand
        },
        toggle(){
            this.expand = !this.expand
            console.log(this.expand)
        }
    },
    computed:{
        ToggleState(){
            return this.expand;
        },
        Option(){
            let value = this.$store.getters['user/getPaymentMode']
            if(value==0)
            return 'option1'
            else
            return 'option2'
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
    #payment-field{
        margin-bottom:20px;
        width:90vw;
        margin-left:5vw;
        border-radius: 30px;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
        height: fit-content;
        padding-top:0px;
    }
    .input-case{
        width:90vw;
        height:54px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
display:inline-flex;
padding-top:0px;
margin-top:0px;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:85vw;
        height:54px;
        border-radius: 30px;
background: #FFF;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-top:0px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #dropdown{
        margin-right:4vw;
        margin-top:25px;
    }
#payment-options{
    padding-bottom:10px;
    padding-top:10px;
    padding-left:5vw;
    padding-right:5vw;
}
.payment-option{
    width:80vw;
    margin-bottom:5px;
}
.payment-active{
    border-radius: 5px;
background: #25AD34;
padding-top:10px;
padding-bottom:10px;
padding-left:3vw;
}
.payment-active h2{
    color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
}
.payment-active h3{
    color: #FFF;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.2px;
}
.payment-passive{
    border-radius: 5px;
    padding-top:10px;
    padding-bottom:10px;
    padding-left:3vw;
}
.payment-passive h2{
    color: #8D8D8D;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
}
.payment-passive h3{
    color: #8D8D8D;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.2px;
}
}
@media only screen and (min-width:481px) and (max-width:960px){
    #payment-field{
        margin-bottom:41.52px;
        width:45vw;
        border-radius: 30px;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
        height: fit-content;
        padding-top:0px;
    }
    .input-case{
        width:45vw;
        height:67px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
display:inline-flex;
padding-top:0px;
margin-top:0px;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:40vw;
        height:67px;
        border-radius: 30px;
background: #FFF;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-top:0px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #dropdown{
        margin-right:4vw;
        margin-top:35px;
        font-size:32px;
    }
#payment-options{
    padding-bottom:10px;
    padding-top:10px;
    padding-left:2.5vw;
    padding-right:2.5vw;
}
.payment-option{
    width:40vw;
    margin-bottom:5px;
    padding-left:3vw;
}
.payment-active{
    border-radius: 5px;
background: #25AD34;
padding-top:15px;
padding-bottom:15px;
}
.payment-active h2{
    color: #FFF;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
}
.payment-active h3{
    color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.2px;
}
.payment-passive{
    border-radius: 5px;
    padding-top:15px;
    padding-bottom:15px;
    padding-left:3vw;
}
.payment-passive h2{
    color: #8D8D8D;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
}
.payment-passive h3{
    color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.2px;
}
}
@media only screen and (min-width:961px){
    #payment-field{
        margin-bottom:6.5vh;
        width:20vw;
        border-radius: 30px;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
        height: fit-content;
        padding-top:0px;
    }
    .input-case{
        width:20vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
display:inline-flex;
padding-top:0px;
margin-top:0px;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:17vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-top:0px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #dropdown{
        margin-top:20px;
    }
    #payment-options{
        padding-top:10px;
        padding-bottom:10px;
    }
.payment-option{
    width:18vw;
    margin-bottom:5px;
    margin-left:1vw;
    padding-top:5px;
    padding-bottom:5px;
}
.payment-active{
    border-radius: 5px;
background: #25AD34;
padding-left:1vw;
}
.payment-active h2{
    color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
}
.payment-active h3{
    color: #FFF;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.2px;
}
.payment-passive{
    border-radius: 5px;
    padding-left:1vw;
}
.payment-passive h2{
    color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
}
.payment-passive h3{
    color: #8D8D8D;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.2px;
}
}

.animate{
    animation: expand;
    animation-fill-mode: forwards;
    animation-duration: 0.1s;
}
@keyframes expand{
    from{
        height: 0px;

    }
    to{
        height: fit-content;
    }
}
</style>