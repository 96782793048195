<template>
    <div id="past-order">
        <div id="assign-heading">
            <h2>Previous Deliveries</h2>
        </div>
    <div id="table">
        <div id="heading">
            <h2 class="heading-text">Rider Name</h2>
            <h2 class="heading-text">Time</h2>
            <h2 class="heading-text">Destination</h2>
            <h2 class="heading-text">Order Id</h2>
            <h2 class="heading-text">Items</h2>
            <h2 class="heading-text"></h2>
        </div>
    <div id="underline"></div>
    <div id="items">
     <div id='content' v-for="order,index in Orders" :key="index">
        <div class="item">
            <h2 class="item-text">{{ order.name }}</h2>
            <h2 class="item-text">{{ order.eta}}</h2>
            <h2 class="item-text">{{ order.destination}}</h2>
            <h2 class="item-text">{{order.orderId }}</h2>
            <h2 class="item-text">{{ order.items}}</h2>
            <div id="details">
                Review
            </div>
        </div>
        <div class="underline1">
            
        </div>
     </div>
    </div>
    </div>
    </div>
</template>

<script>
export default {
 data(){
    return{
        orders:[]
    }
 },
 computed:{
    Orders(){
        return this.orders
    }
 },
 mounted(){
    this.orders=[
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
        {name:'driver name',eta:'23',destination:'1.5km>destination',orderId:'121232Asd!12',items:'item1,item2,item3......'},
    ]
 }   
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#past-order{
    width:100vw;
    margin-top:10px;
    height: auto;
    overflow-x:hidden ;
}
#past-order::-webkit-scrollbar{
    display: none;
}
@media only screen and (max-width:480px){
    #assign-heading{
        padding-left:3vw;
        padding-top:2vh;
    }
    #assign-heading h2{
        color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-bottom:15px;
    }
#table{
    width:96vw;
    height:60vh;
    margin-top:3vh;
    margin-left:2vw;
    margin-right:2vw;
    border-radius: 6px;
    border: 1px solid rgba(30, 30, 30, 0.20);
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
padding-left:2vw;
padding-right:2vw;
padding-top:2vh;
padding-bottom:2vh;
overflow:auto
}
#table::-webkit-scrollbar{
    display: none;
}
#heading{
    width:92vw;
    display:grid;
    grid-template-columns: 25vw 20vw 40vw 30vw 40vw 30vw;
    grid-gap:3vw;
    height:3vh;
}
.heading-text{
    color: #413F3F;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align:center
}
#underline{
    width:205vw;
background: #8D8D8D;
height:1px;
margin-top:13px;
margin-bottom:21px;
}
.underline1{
    width:205vw;
background: #8D8D8D;
height:1px;
margin-top:1px;
margin-bottom:19px;
}
#items{
height:55vh;
overflow: auto;
width:fit-content;
padding-top:1.5vh;
}
#items::-webkit-scrollbar{
    display: none;
}
.item{
    display:grid;
    grid-template-columns: 25vw 20vw 40vw 30vw 45vw 30vw;
    grid-gap:3vw;
    height:4vh;
}
.item-text{
    color: #413F3F;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
text-align:center
}
#details{
    border-radius: 16px;
background: #25AD34;
width:28vw;
height:32px;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 32px;
margin-top:-5px;
}
}
@media only screen and (min-width:481px) and (max-width:960px){
    #assign-heading{
        padding-left:3vw;
        padding-top:2vh;
    }
    #assign-heading h2{
        color: #413F3F;
font-family: Inter;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-bottom:15px;
    }
#table{
    width:96vw;
    height:64vh;
    margin-top:3vh;
    margin-left:2vw;
    margin-right:2vw;
    border-radius: 6px;
    border: 1px solid rgba(30, 30, 30, 0.20);
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
    overflow: auto;
    padding-left:2vw;
    padding-right:2vw;
    padding-top:2vh;
    padding-bottom:2vh;
}
#table::-webkit-scrollbar{
    display: none;
}
#heading{
    width:92vw;
    display:grid;
    grid-template-columns: 13vw 9vw 17vw 15vw 17vw 14vw ;
    grid-gap:1vw;
    height:5vh;
}
.heading-text{
    color: #413F3F;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align:center
}
#underline{
    width:92vw;
background: #8D8D8D;
height:1px;
margin-top:13px;
margin-bottom:21px;
}
.underline1{
    width:95vw;
background: #8D8D8D;
height:1px;
margin-top:1px;
margin-bottom:19px;
}
#items{
    height:55vh;
    overflow: auto;
    width:fit-content;
    padding-top:1.5vh;
    }
    #items::-webkit-scrollbar{
        display: none;
    }
    .item{
        display:grid;
        grid-template-columns: 13vw 9vw 17vw 19vw 17vw 18vw;
        grid-gap:1vw;
        height:5vh;
    }
    .item-text{
        color: #413F3F;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align:center
    }
    #details{
        border-radius: 16px;
    background: #25AD34;
    width:12vw;
    height:42px;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    margin-left:4vw;
    margin-top:-3px;
    }
}
@media only screen and (min-width:961px){
    #assign-heading{
        padding-left:3vw;
        padding-top:2vh;
    }
    #assign-heading h2{
        color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-bottom:15px;
    }
    #table{
        width:96vw;
        height:63vh;
        margin-top:3vh;
        margin-left:2vw;
        margin-right:2vw;
        border-radius: 6px;
        border: 1px solid rgba(30, 30, 30, 0.20);
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
        overflow:auto;
        padding-left:2vw;
        padding-right:2vw;
        padding-top:2vh;
        padding-bottom:2vh;
    }
#table::-webkit-scrollbar{
    display: none;
}
#heading{
    width:92vw;
    display:grid;
    grid-template-columns: 13vw 9vw 17vw 15vw 17vw 14vw ;
    grid-gap:1vw;
    height:5vh;
}
.heading-text{
    color: #413F3F;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align:center
}
#underline{
    width:92vw;
    background: #8d8d8d52;
height:1px;
margin-top:13px;
margin-bottom:21px;
}
.underline1{
    width:92vw;
background: #8d8d8d52;
height:1px;
margin-top:1px;
margin-bottom:19px;
}
#items{
    height:55vh;
    overflow: auto;
    width:fit-content;
    padding-top:1.5vh;
    }
    #items::-webkit-scrollbar{
        display: none;
    }
    .item{
        display:grid;
        grid-template-columns: 13vw 9vw 17vw 19vw 17vw 18vw;
        grid-gap:1vw;
        height:5vh;
    }
    .item-text{
        color: #413F3F;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align:center
    }
    #details{
        border-radius: 16px;
    background: #25AD34;
    width:8vw;
    height:42px;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    margin-left:4vw;
    margin-top:-8px;
    cursor: pointer;
    }
}
</style>