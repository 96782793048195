export default {
  getModal(state) {
    return state.modal;
  },
  getReviewModal(state) {
    return state.reviewModal;
  },
  getId(state) {
    return state.orderDetails;
  },
  getConfirmationModal(state) {
    return state.confirmationModal;
  },
};
