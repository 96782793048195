<template>
    <div id="week-review">
      <div id="back">
        <back-button @click="back"></back-button>
      </div>
      <dues-modal v-if="DuesModal"></dues-modal>
        <confirm-modal v-if="ConfirmModal"></confirm-modal>
      <review-modal v-if="Modal" :value="review"></review-modal>
      <h2 id="weekNumber">Week{{ week }}</h2>
      <div id="underline"></div>
      <div id="table">
        <div id="headings">
            <div class="heading">Day</div>
            <div class="heading">Order Id</div>
            <div class="heading">Order Value</div>
            <div class="heading">Payment Mode</div>
            <div class="heading">Payment Received</div>
            <div class="heading">Rider Name</div>
            <div class="heading">Customer Name</div>
            <div class="heading">Review</div>
        </div>
    <div class="divider"></div>
    <div id="data">
       <div  v-for="payment,index in Payments" :key="index">
        <div class="row">
            <div class="row-field">{{ payment.day }}</div>
            <div class="row-field">{{ payment.orderId }}</div>
            <div class="row-field">{{ payment.value }}</div>
            <div class="row-field">{{ payment.mode }}</div>
            <div class="row-field">{{ payment.payment }}</div>
            <div class="row-field">{{ payment.rider }}</div>
            <div class="row-field">{{ payment.customer }}</div>
            <div class="review" @click="toggleModal(payment)">Review</div>
        </div>
        <div class="divider-row"></div>
       </div>
    </div>
      </div>
      <out-standing :Month="month" :Week="week"></out-standing>
    </div>
</template>

<script>
import BackButton from '../../components/general/back-button.vue'
import OutStanding from '../../components/payment/week-outstanding.vue'
import ReviewModal from '../../components/payment/review-modal.vue'
import ConfirmModal from '../../components/payment/modals/confirmation-modal.vue'
import DuesModal from '../../components/payment/modals/week-dues.vue'
export default {
    data(){
        return{
            week:'',
            month:'',
            review:''
        }
    },
    methods:{
        back(){
            this.$router.push({name:'home'})
        },
        toggleModal(value){
            console.log(value)
            this.$store.commit('payments/setReviewModal',{value:true})
            this.review = {
                orderNo:'AAD12121',
                orderValue:'123',
                paymentMode:'Online',
                orderItems:'Item1,Item2,Item3,Item4',
                deliveryAddress:'The address where delivery was made',
                customerName:'Name of Customer',
                deliveredBy:'Name of Driver'
            }
        }
    },
    components:{BackButton,OutStanding,ReviewModal,ConfirmModal,DuesModal},
    computed:{
        Modal(){
            return this.$store.getters['payments/getReviewModal']
        },
        DuesModal(){
            return this.$store.getters['payments/getModal']
        },
        ConfirmModal(){
            return this.$store.getters['payments/getConfirmationModal']
        },
        Payments(){
            return [
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
                {day:"17th",orderId:'OID1',value:'56',mode:'Online',payment:'Yes',rider:'Rider Name',customer:'Customer Name'},
            ]
        }
    },
    async mounted(){
        this.month = this.$route.params.month;
        this.week = this.$route.params.week;
        // make a call to backend for data
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
    #week-review{
        width:100vw;
        padding-bottom:2vh;
    }
    #back{
        margin-top:2.5vh;
        margin-left:2.5vw;
    }
    #weekNumber{
        color: var(--Brand-color-2, #413F3F);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-top:30px;
        margin-left:3vw;
    }
    #underline{
        width: 74.396px;
height: 2.555px;
flex-shrink: 0;
border-radius: 6px;
background: var(--Brand-color-3, #8D8D8D);
margin-top:2px;
margin-left:3vw;
    }
    #table{
        margin-top:2vh;
        width:100vw;
        padding-left:2vw;
        padding-right:2vw;
        overflow-x:auto;
        height: fit-content;
        max-height: 60vh;
        padding-top:2vh;
        padding-bottom:2vh;
        overflow:auto ;
    }
    #table::-webkit-scrollbar{
        display: none;
    }
    #headings{
        display: grid;
        width:96vw;
        grid-template-columns: repeat(7,24vw) 30vw;
    }
    .heading{
        color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
text-align: center;
    }
.divider{
    height:1px;
    background: #00000097;
    width:196vw;
    margin-left:2vw;
    margin-top:7.5px;
    margin-bottom:7.5px;
}
#data{
    width:96vw;
}
.row{
    display: grid;
        width:fit-content;
        overflow-x:auto ;
        grid-template-columns: repeat(7,24vw) 30vw;
        padding-left:2vw;
}
.divider-row{
    height:1px;
    background: #00000097;
    width:196vw;
    margin-left:2vw;
    margin-top:7.5px;
    margin-bottom:7.5px;
}
.row-field{
    color: var(--Brand-color-2, #413F3F);
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
}
.review{
    border-radius: 8px;
background: #25AD34;
width:20vw;
height:45px;
margin-left:2vw;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 45px;
}
}
@media only screen and (min-width:481px) and (max-width:960px){
    #back{
        margin-top:2.5vh;
        margin-left:2.5vw;
    }
    #weekNumber{
        color: var(--Brand-color-2, #413F3F);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-top:30px;
        margin-left:3vw;
    }
    #underline{
        width: 94.396px;
height: 2.555px;
flex-shrink: 0;
border-radius: 6px;
background: var(--Brand-color-3, #8D8D8D);
margin-top:3px;
margin-left:3vw;
    }
#table{
    margin-top:2vh;
    width:100vw;
    padding-left:3vw;
    padding-right:3vw;
    overflow:auto;
    height:60vh;
    padding-top:2vh;
    padding-bottom:2vh;
    overflow:auto ;
}
#table::-webkit-scrollbar{
    display: none;
}
#headings{
    display: grid;
    width:94vw;
    grid-template-columns: 10vw repeat(2,14vw) repeat(2,18vw) repeat(2,20vw) 20vw;
}

.heading{
    color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
text-align: center;
}
.divider{
height:1px;
background: #00000097;
width:130vw;
margin-left:2vw;
margin-top:12.5px;
margin-bottom:12.5px;
}
#data{
    width:fit-content;
    overflow: auto;
}
#data::-webkit-scrollbar{
    display: none;
}
.row{
    display: grid;
    width:fit-content;
    grid-template-columns: 10vw repeat(2,14vw) repeat(2,18vw) repeat(2,20vw) 20vw;
    padding-left:2vw;
}
.divider-row{
    height:1px;
    background: #00000097;
    width:fill;
    margin-left:2vw;
    margin-top:12.5px;
    margin-bottom:12.5px;
}
.row-field{
    color: var(--Brand-color-2, #413F3F);
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
}
.review{
    border-radius: 8px;
background: #25AD34;
width:20vw;
height:45px;
margin-left:2vw;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 45px;
}
}
@media only screen and (min-width:961px){
    #back{
        margin-top:2.5vh;
        margin-left:1.5vw;
    }
    #weekNumber{
        color: var(--Brand-color-2, #413F3F);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-top:30px;
        margin-left:2vw;
    }
    #underline{
        width: 94.396px;
height: 2.555px;
flex-shrink: 0;
border-radius: 6px;
background: var(--Brand-color-3, #8D8D8D);
margin-top:3px;
margin-left:2vw;
    }
    #table{
        margin-top:3vh;
        width:100vw;
        padding-left:3vw;
        padding-right:3vw;
        overflow-y:scroll;
        overflow-x: hidden;
        height: fit-content;
        max-height: 65vh;
        padding-top:2vh;
        padding-bottom:2vh;
        overflow-x:hidden ;
    }
    #table::-webkit-scrollbar{
        display: none;
    }
    #headings{
        display: grid;
        width:94vw;
        overflow-x:hidden ;
        padding-left:2vw;
        padding-right:2vw;
        grid-template-columns: 6vw repeat(2,8vw) repeat(2,12vw) repeat(2,12vw) 16vw;
    }
    .heading{
        color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: center;
    }
    .divider{
    height:1px;
    background: #00000097;
    width:90vw;
    margin-left:1vw;
    margin-top:10.5px;
    margin-bottom:10.5px;
    }
    #data{
        width:94vw;
        overflow-x:hidden;
    }
    .row{
        display: grid;
        width:94vw;
        padding-left:2vw;
        padding-right:2vw;
        grid-template-columns: 6vw repeat(2,8vw) repeat(2,12vw) repeat(2,12vw) 16vw;
    }
    .divider-row{
        height:1px;
        background: #00000097;
        width:90vw;
        margin-left:1vw;
        margin-top:10.5px;
        margin-bottom:10.5px;
    }
    .row-field{
        color: var(--Brand-color-2, #413F3F);
    text-align: center;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    }
    .review{
        border-radius: 8px;
    background: #25AD34;
    width:10vw;
    height:45px;
    margin-left:5vw;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    cursor: pointer;
    }
}
</style>