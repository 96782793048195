import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";
export default {
  namespaced: true,
  state() {
    return {
      signupPage: 0, // signup page
      forgotPage: 0, // forgot password page
      homePage: 0, // this will control the 4 main screens in the landing section of app
      paymentMode: 0,
      address: "",
      zipCode: "",
      latitude: 0,
      longitude: 0,
      notification: false,
      notificationData: {},
    };
  },
  mutations,
  getters,
  actions,
};
