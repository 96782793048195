<template>
    <div id="billing">
        <h2 class="billing-title">Billings</h2>
        <h2 class="billing-title">Select Month</h2>
        <div id="month-nav">
         <div id="left-button" @click="slideLeft">
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="8" viewBox="0 0 7 8" fill="none" id="mobile-left">
                <path d="M5.61511 1.03078L1.08398 3.64683C0.750651 3.83928 0.750652 4.32041 1.08399 4.51286L5.61511 7.12891" stroke="white" stroke-linecap="round"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none" id="desktop-left">
                <path d="M11 1L1.64496 7.08078C1.34162 7.27795 1.34162 7.72205 1.64496 7.91922L11 14" stroke="white" stroke-linecap="round"/>
              </svg>
         </div>
         <div id="months" ref="month" class="scroll-content">
            <div class="month"  :class="Month=='January'?'month-active':'month-passive'" @click="toggleMonth('January')">January</div>
            <div class="month" :class="Month=='February'?'month-active':'month-passive'" @click="toggleMonth('February')">February</div>
            <div class="month" :class="Month=='March'?'month-active':'month-passive'" @click="toggleMonth('March')">March</div>
            <div class="month" :class="Month=='April'?'month-active':'month-passive'" @click="toggleMonth('April')">April</div>
            <div class="month" :class="Month=='May'?'month-active':'month-passive'" @click="toggleMonth('May')">May</div>
            <div class="month" :class="Month=='June'?'month-active':'month-passive'" @click="toggleMonth('June')">June</div>
            <div class="month" :class="Month=='July'?'month-active':'month-passive'" @click="toggleMonth('July')">July</div>
            <div class="month" :class="Month=='August'?'month-active':'month-passive'" @click="toggleMonth('August')">August</div>
            <div class="month" :class="Month=='September'?'month-active':'month-passive'" @click="toggleMonth('September')">September</div>
            <div class="month" :class="Month=='October'?'month-active':'month-passive'" @click="toggleMonth('October')">October</div>
            <div class="month" :class="Month=='November'?'month-active':'month-passive'" @click="toggleMonth('November')">November</div>
            <div class="month" :class="Month=='December'?'month-active':'month-passive'" @click="toggleMonth('December')">December</div>
         </div>
         <div id="right-button" @click="slideRight">
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="8" viewBox="0 0 7 8" fill="none" id="mobile-right">
                <path d="M0.724731 7.4907L5.25586 4.87465C5.58919 4.6822 5.58919 4.20108 5.25586 4.00863L0.724731 1.39258" stroke="white" stroke-linecap="round"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none" id="desktop-right">
                <path d="M1 14L10.355 7.91922C10.6584 7.72205 10.6584 7.27795 10.355 7.08078L1 1" stroke="white" stroke-linecap="round"/>
              </svg>
         </div>
        </div>
     <div id="payment-weeks">
        <div id="week-headings">
            <div class="week-heading">Week</div>
            <div class="week-heading">Total Amount</div>
            <div class="week-heading">Review</div>
        </div>
        <div id="week-separator"></div>
        <div id="week-content" v-for="value,index in CurrentMonth" :key="index">
            <div class="week-data">
                <div class="week-name">{{ value.name }}</div>
                <div class="week-total">${{ value.amount }}</div>
                <div class="week-review" @click="redirect(index+1)">Review Week</div>
            </div>
            <div id="week-separator"></div>
        </div>
     </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            month:''
        }
    },
 computed:{
    Payments(){
        let obj = {
            'January':[
                {name:'week1',amount:100},
                {name:'week2',amount:100},
                {name:'week3',amount:100},
                {name:'week4',amount:100}
            ],
            'February':[
                {name:'week1',amount:100},
                {name:'week2',amount:100},
                {name:'week3',amount:100},
                {name:'week4',amount:100}
            ],
            'March':[
                {name:'week1',amount:100},
                {name:'week2',amount:100},
                {name:'week3',amount:100},
                {name:'week4',amount:100}
            ],
            'April':[
                {name:'week1',amount:100},
                {name:'week2',amount:100},
                {name:'week3',amount:100},
                {name:'week4',amount:100}
            ],
            'May':[
                {name:'week1',amount:100},
                {name:'week2',amount:100},
                {name:'week3',amount:100},
                {name:'week4',amount:100}
            ],
            'June':[
                {name:'week1',amount:100},
                {name:'week2',amount:100},
                {name:'week3',amount:100},
                {name:'week4',amount:100}
            ],
            'July':[
                {name:'week1',amount:100},
                {name:'week2',amount:100},
                {name:'week3',amount:100},
                {name:'week4',amount:100}
            ],
            'August':[
                {name:'week1',amount:100},
                {name:'week2',amount:100},
                {name:'week3',amount:100},
                {name:'week4',amount:100}
            ],
            'September':[
                {name:'week1',amount:100},
                {name:'week2',amount:100},
                {name:'week3',amount:100},
                {name:'week4',amount:100}
            ],
            'October':[
                {name:'week1',amount:100},
                {name:'week2',amount:100},
                {name:'week3',amount:100},
                {name:'week4',amount:100}
            ],
            'November':[
                {name:'week1',amount:100},
                {name:'week2',amount:100},
                {name:'week3',amount:100},
                {name:'week4',amount:100}
            ],
            'December':[
                {name:'week1',amount:100},
                {name:'week2',amount:100},
                {name:'week3',amount:100},
                {name:'week4',amount:100}
            ]
        }
        return obj
    },
Month(){
    return this.month;
},
CurrentMonth(){
 let payments = this.Payments;
 return payments[this.month];
}
 },
 methods:{
    toggleMonth(val){
        this.month = val;
    },
    // these are arrow key handlers
    slideLeft(){
        const scrollContainer = this.$refs.month
        scrollContainer.scrollLeft -= 200
    },
    slideRight(){
        const scrollContainer = this.$refs.month
        scrollContainer.scrollLeft += 200
    },
    redirect(week){
        const Months = {'January':1,'February':2,'March':3,'April':4,'May':5,
    'June':6,'July':7,'August':8,'September':9,'October':10,'November':11,'December':12}
        let month = Months[this.currentMonth]
        let url = `/week/${month}/${week}`
        this.$router.push(url);
    }
 },
 async mounted(){
    const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1
  switch(currentMonth){
    case 1:
        this.month = 'January'
        break
    case 2:
        this.month = 'February'
        break
    case 3:
        this.month = 'March'
        break
    case 4:
        this.month = 'April'
        break
    case 5:
        this.month = 'May'
        break
    case 6:
        this.month = 'June'
        break
    case 7:
        this.month = 'July'
        break
    case 8:
        this.month = 'August'
        break
    case 9:
        this.month = 'September'
        break
    case 10:
        this.month = 'October'
        break
    case 11:
        this.month = 'November'
        break
    case 12:
        this.month = 'December'
  }
 }   
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
    #billing{
        border-radius: 6px;
background: #FFF;
box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
width:95vw;
margin-left:2.5vw;
margin-right:2.5vw;
overflow-x:hidden;
overflow-y:auto ;
height:fit-content;
padding-bottom:2vh;
margin-top:2vh;
    }
#billing::-webkit-scrollbar{
    display: none;
}
.billing-title{
    color: #413F3F;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:4vw;
margin-top:10px;
}
#month-nav{
    width:95vw;
    margin-top:2vh;
    height:fit-content;
    padding-left:2.5vw;
    padding-right:2.5vw;
    padding-top:10px;
    padding-bottom:10px;
    overflow:hidden;
    display:grid;
    grid-template-columns: 10vw 70vw 10vw;
}
#left-button{
    border-radius: 2px;
background: #25AD34;
width: 25px;
height: 25px;
flex-shrink: 0;
display: flex;
align-items: center;
justify-content: center;
margin-left:2vw;
}
#months{
    overflow-y:hidden;
    overflow-x:auto;
    width:70vw;
    padding-left:1vw;
    padding-right:1vw;
    padding-bottom:5px;
    display: flex;
    flex-direction: row;
}
#months::-webkit-scrollbar{
    display: none;
}
#right-button{
    border-radius: 2px;
background: #25AD34;
width: 25px;
height: 25px;
flex-shrink: 0;
display: flex;
align-items: center;
justify-content: center;
float:right;
margin-left:1vw;
}
.month{
    margin-left:1vw;
    margin-right:1vw;
    width:fit-content
    
}
.month-active{
    border-radius: 5px;
background: #25AD34;
height:25px;
color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: 25px;
letter-spacing: 0.2px;
width:fit-content;
padding-left:4px;
padding-right:4px;
}
.month-passive{
    color: #413F3F;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: 25px;
letter-spacing: 0.2px;
width:fit-content;
height:25px;
}
.scroll-content {
    width: 600px;
    transition: transform 0.6s ease; /* Add a smooth transition for the transform property */
  }
#desktop-left{
    display: none;
}
#desktop-right{
    display: none;
}
#payment-weeks{
    width:95vw;
    margin-left:2.5vw;
    margin-right:2.5vw;
}
#week-headings{
    width:90vw;
    padding-left:3vw;
    padding-right:3vw;
    display: grid;
    grid-template-columns: repeat(3,28vw);
    padding-top:10px;
    margin-bottom:10px;
}
.week-heading{
    color: #413F3F;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
text-align:center
}
#week-separator{
    height:0.5px;
    width:84vw;
    background: #000000a1;
    margin-top:5px;
    margin-bottom:5px;
    margin-left:3vw;
}
#week-content{
   width:90vw;

}
.week-data{
    display: grid;
    grid-template-columns: repeat(3,28vw);
    margin-top:1.75vh;
    margin-bottom:1.75vh;
    padding-left:3vw;
    padding-right:3vw;
}
.week-name{
    color: #413F3F;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0.2px;
    text-align:center
}
.week-total{
    color: #413F3F;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0.2px;
    text-align:center
}
.week-review{
    border-radius: 8px;
    background: #25AD34;
    width:95px;
    height:44px;
    margin-left:10px;
    color: #FFF;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 44px;
}
}
@media only screen and (min-width:481px) and (max-width:960px){
    #billing{
        border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
    width:90vw;
    margin-left:5vw;
    margin-right:5vw;
    overflow-x:hidden;
    overflow-y:auto ;
    height:fit-content;
    padding-bottom:3vh;
    margin-top:2vh;
    }
    #billing::-webkit-scrollbar{
    display: none;
    }
    .billing-title{
    color: #413F3F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-left:3vw;
    margin-top:15px;
    }
    #month-nav{
        width:90vw;
        margin-top:2vh;
        height:fit-content;
        padding-left:2vw;
        padding-right:2vw;
        padding-top:10px;
        padding-bottom:10px;
        overflow:hidden;
        display:grid;
        grid-template-columns: 8vw 70vw 8vw;
    }
    #left-button{
        border-radius: 2px;
    background: #25AD34;
    width: 45px;
    height: 45px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left:2vw;
    }
    #months{
        overflow-y:hidden;
        overflow-x:auto;
        width:70vw;
        padding-left:1vw;
        padding-right:1vw;
        padding-bottom:5px;
        display: flex;
        flex-direction: row;
    }
    #months::-webkit-scrollbar{
        display: none;
    }
    #right-button{
        border-radius: 2px;
    background: #25AD34;
    width: 45px;
    height: 45px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    float:right;
    margin-left:1vw;
    }
    .month{
        margin-left:1.5vw;
        margin-right:1.5vw;
        width:fit-content
        
    }
    .month-active{
        border-radius: 5px;
    background: #25AD34;
    height:45px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 23px;
    font-style: normal;
    font-weight: 570;
    line-height: 45px;
    letter-spacing: 0.2px;
    width:fit-content;
    padding-left:6px;
    padding-right:6px;
    }
    .month-passive{
        color: #413F3F;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 570;
    line-height: 45px;
    letter-spacing: 0.2px;
    line-break: 45px;
    width:fit-content;

    }
    .scroll-content {
        width: 600px;
        transition: transform 0.6s ease; /* Add a smooth transition for the transform property */
      }
    #mobile-left{
        display: none;
    }
    #mobile-right{
        display: none;
    }
    #payment-weeks{
    width:90vw;
}
#week-headings{
    width:90vw;
    padding-left:3vw;
    padding-right:3vw;
    display: grid;
    grid-template-columns: repeat(3,28vw);
    padding-top:10px;
    margin-bottom:10px;
}
.week-heading{
    color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
text-align:center
}
#week-separator{
    height:0.5px;
    width:78vw;
    background: #000000a1;
    margin-top:5px;
    margin-bottom:5px;
    margin-left:6vw;
}
#week-content{
   width:90vw;

}
.week-data{
    display: grid;
    grid-template-columns: repeat(3,28vw);
    margin-top:2.15vh;
    margin-bottom:2.15vh;
    padding-left:3vw;
    padding-right:3vw;
}
.week-name{
    color: #413F3F;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0.2px;
    text-align:center
}
.week-total{
    color: #413F3F;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0.2px;
    text-align:center
}
.week-review{
    border-radius: 8px;
    background: #25AD34;
    width:125px;
    height:44px;
    margin-left:7vw;
    color: #FFF;
text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 44px;
padding-left:4px;
padding-right:4px;
}
}


@media only screen and (min-width:961px){
    #billing{
        border-radius: 6px;
background: #FFF;
box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
width:70vw;
margin-left:15vw;
margin-right:15vw;
overflow-x:hidden;
overflow-y:auto ;
height:fit-content;
padding-bottom:2vh;
margin-top:3vh;
    }
#billing::-webkit-scrollbar{
    display: none;
}
.billing-title{
    color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
margin-left:1.5vw;
margin-top:15px;
}
#month-nav{
    width:70vw;
    margin-top:2vh;
    height:fit-content;
    padding-left:2vw;
    padding-right:2vw;
    padding-top:10px;
    padding-bottom:10px;
    overflow:hidden;
    display:grid;
    grid-template-columns: 6vw 54vw 6vw;
}
#left-button{
    border-radius: 2px;
background: #25AD34;
width: 45px;
height: 45px;
flex-shrink: 0;
display: flex;
align-items: center;
justify-content: center;
margin-left:2vw;
cursor: pointer;
}
#months{
    overflow-y:hidden;
    overflow-x:auto;
    width:54vw;
    padding-left:1vw;
    padding-right:1vw;
    padding-bottom:5px;
    display: flex;
    flex-direction: row;
}
#months::-webkit-scrollbar{
    display: none;
}
#right-button{
    border-radius: 2px;
background: #25AD34;
width: 45px;
height: 45px;
flex-shrink: 0;
display: flex;
align-items: center;
justify-content: center;
float:right;
margin-left:1vw;
cursor: pointer;
}
.month{
    margin-left:1vw;
    margin-right:1vw;
    width:fit-content
    
}
.month-active{
    border-radius: 5px;
background: #25AD34;
height:45px;
color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 21px;
font-style: normal;
font-weight: 570;
line-height: 45px;
letter-spacing: 0.2px;
width:fit-content;
padding-left:6px;
padding-right:6px;
cursor: pointer;
}
.month-passive{
    color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 570;
line-height: 45px;
letter-spacing: 0.2px;
line-break: 45px;
width:fit-content;
cursor: pointer;
}
.scroll-content {
    width: 600px;
    transition: transform 0.6s ease; /* Add a smooth transition for the transform property */
  }
#mobile-left{
    display: none;
}
#mobile-right{
    display: none;
}
#payment-weeks{
    width:70vw;
    padding-top:2vh;
}
#week-headings{
    width:70vw;
    padding-left:3.5vw;
    padding-right:3.5vw;
    display: grid;
    grid-template-columns: repeat(3,21vw);
    padding-top:10px;
    margin-bottom:10px;
}
.week-heading{
    color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
text-align:center
}
#week-separator{
    height:0.5px;
    width:54vw;
    background: #000000a1;
    margin-top:5px;
    margin-bottom:5px;
    margin-left:8vw;
}
#week-content{
   width:70vw;

}
.week-data{
    display: grid;
    grid-template-columns: repeat(3,21vw);
    margin-top:1.15vh;
    margin-bottom:1.15vh;
    padding-left:3.5vw;
    padding-right:3.5vw;
}
.week-name{
    color: #413F3F;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0.2px;
    text-align:center;
    padding-left:0.5vw;
}
.week-total{
    color: #413F3F;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0.2px;
    text-align:center
}
.week-review{
    border-radius: 8px;
    background: #25AD34;
    width:125px;
    height:44px;
    margin-left:7.4vw;
    color: #FFF;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 44px;
padding-left:4px;
padding-right:4px;
cursor: pointer;
}
}
</style>