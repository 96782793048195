export default {
  setModal(state, payload) {
    state.modal = payload.value;
  },
  setReviewModal(state, payload) {
    state.reviewModal = payload.value;
  },
  setId(state, payload) {
    state.orderDetails = payload.value;
  },
  setConfirmationModal(state, payload) {
    state.confirmationModal = payload.value;
  },
};
