const get_address_api_key = "bnlhzM0UdEWYNTm_jarU8Q40453";
const hostName = "https://seal-app-brp6e.ondigitalocean.app/api/v1";
import router from "../../router/router";
import Cookies from "js-cookie";
import axios from "axios";
export default {
  async getAddress(context, payload) {
    // to find all the address corresponding to given postcode
    let url = `https://api.getAddress.io/location/${payload.value}?api-key=${get_address_api_key}`;
    try {
      let locations = await axios.get(url);
      let address = locations.data;
      return address.suggestions;
    } catch (err) {
      console.log(err);
    }
  },
  async signUp(context, payload) {
    try {
      const reqData = {
        email: payload.email,
        restaurant_name: payload.restaurant_name,
        phone_no: payload.phone_no,
        password: payload.password,
        confirmPassword: payload.confirmPassword,
        address: payload.address,
        zip_code: payload.zip_code.split(",")[0],
      };
      const reqUrl = `${hostName}/outlet/signup`;
      let res = await axios.post(reqUrl, reqData);
      console.log(res);
      return res.status;
    } catch (err) {
      console.log(err);
      return 400;
    }
  },
  async signIn(context, payload) {
    try {
      const reqBody = {
        email: payload.email,
        password: payload.password,
      };
      const reqUrl = `${hostName}/outlet/signin`;
      let res = await axios.post(reqUrl, reqBody);
      if (res.status == 200) {
        Cookies.set("email", res.data.payload.email, { expires: 30 });
        Cookies.set("id", res.data.payload.id, { expires: 30 });
        Cookies.set("restaurant_name", res.data.payload.restaurant_name, {
          expires: 30,
        });
        Cookies.set("userType", res.data.payload.userType, { expires: 30 });
        Cookies.set("token", res.data.token, { expires: 30 });
        Cookies.set("authenticated", true, { expires: 30 });
        router.push({ name: "home" });
        return { field: 0 };
      }
      if (res.status == 404) {
        return { field: 1, message: "No account found" };
      }
      if (res.status == 401) {
        return { field: 2, message: "Wrong password" };
      }
    } catch (err) {
      return { field: 2, message: "Wrong password" };
    }
  },
  async forgotPassword1(context, payload) {
    try {
      const reqBody = {
        email: payload.email,
      };
      const reqUrl = `${hostName}/outlet/generate-otp-mail`;
      let res = await axios.post(reqUrl, reqBody);
      return res.status;
    } catch (err) {
      return 404;
    }
  }, // this is for email
  async forgotPassword2(context, payload) {
    try {
      const reqBody = {
        email: Cookies.get("password-email"),
        newPassword: payload.password,
        confirmPassword: payload.confirm,
        otp: payload.otp,
      };
      console.log(reqBody);
      const reqUrl = `${hostName}/outlet/forgot-password`;
      let res = await axios.post(reqUrl, reqBody);
      console.log(res);
      return res.status;
    } catch (err) {
      console.log(err);
      return 404;
    }
  }, // this is for otp verification and setting new password
  async putToken(context, payload) {
    // this will put the token
    try {
      const reqBody = {
        fcm_token: payload.value,
      };
      const reqUrl = `${hostName}/outlet/update-fcm-outlet`;
      const headers = {
        Authorization: "Bearer " + Cookies.get("token"),
      };
      let res = await axios.put(reqUrl, reqBody, {
        headers: headers,
      });
      console.log(res);
    } catch (err) {
      console.log(err);
      return;
    }
  },
  async logout() {
    // logout function
  },
  async deleteToken() {},
};
