import { createRouter, createWebHistory } from "vue-router";
import OnboardingRoutes from "./onboarding.js";
import DeliveryRoutes from "./delivery.js";
import PaymentRoutes from "./payment.js";
import HomePage from "../views/home/landing-page.vue";
let routes = [
  {
    name: "home",
    path: "/",
    component: HomePage,
  },
];
routes = routes.concat(OnboardingRoutes, DeliveryRoutes, PaymentRoutes);
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
