<template>
    <div id="container">
        <header-comp></header-comp>
        <keep-alive>
        <component :is="component"></component>
        </keep-alive>
    </div>
</template>

<script>
import HeaderComp from '../../components/home/header-comp.vue'
import AssignDelivery from '../../components/deliveries/assign-order.vue'
import ActiveDelivery from '../../components/deliveries/active-order.vue'
import PastDelivery from '../../components/deliveries/past-order.vue'
import PaymentLanding from '../../components/payment/landing-page.vue'
export default {
    components:{HeaderComp,AssignDelivery,ActiveDelivery,PastDelivery,PaymentLanding},
    computed:{
        component(){
            let activeComp = this.$store.getters['user/getHomePage']
            if(activeComp==0)
            return 'AssignDelivery'
            else if(activeComp==1)
            return 'ActiveDelivery'
            else if(activeComp==2)
            return 'PastDelivery'
            else if(activeComp == 3)
            return 'PaymentLanding'
            else
            return 'AssignDelivery' 
        }
    },
}
</script>


<style scoped>
#container{
    position:fixed;
    width:100vw;
    height: 100vh;
    overflow-x:hidden;
    overflow-y:auto;
}
#container::-webkit-scrollbar{
    display: none;
}
</style>