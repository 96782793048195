<template>
    <div id="submit-alternate">
        <h2>{{ Text }}</h2>
    </div>
</template>

<script>
export default {
    props:['Text']
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:420px){
    #submit-alternate{
        border-radius: 16px;
        width: 40vw;
        margin-left:30vw;
        margin-top:30px;
        height: 47px;
        background: var(--brand-color, #5C5C8B);
    }
    #submit-alternate h2{
        color: #FFF;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 47px;
    }
}
@media only screen and (min-width:480px) and (max-width:960px){
    #submit-alternate{
        border-radius: 16px;
        width: 30vw;
        margin-left:35vw;
        height: 57px;
        background: var(--brand-color, #5C5C8B);
        margin-top:30px;
    }
    #submit-alternate h2{
        color: #FFF;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 57px;
    }
}
@media only screen and (min-width:961px){
    #submit-alternate{
        border-radius: 16px;
        width: 20vw;
        margin-left:40vw;
        height: 57px;
        background: var(--brand-color,#5C5C8B);
        margin-top:-10px;
        cursor: pointer;
    }
    #submit-alternate h2{
        color: #FFF;
text-align: center;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 57px;
    }
}
</style>