<template>
    <div id="container">
    <div id="form">
        <back-button id="back-button" @click="back"></back-button>
        <div id="sign-title">
            <svg xmlns="http://www.w3.org/2000/svg" width="74" height="5" viewBox="0 0 74 5" fill="none">
                <path d="M73.0111 2.64648L70.7017 0.337083L68.3923 2.64648L70.7017 4.95589L73.0111 2.64648ZM70.7017 2.24648H0.527344V3.04648H70.7017V2.24648Z" fill="#25AD34"/>
              </svg>
              <h2>Signup</h2>
              <svg xmlns="http://www.w3.org/2000/svg" width="73" height="5" viewBox="0 0 73 5" fill="none">
                <path d="M0.333177 2.64648L2.64258 4.95589L4.95198 2.64648L2.64258 0.337083L0.333177 2.64648ZM72.8169 2.24648H2.64258V3.04648H72.8169V2.24648Z" fill="#25AD34"/>
              </svg>
        </div>
        <div id="form-box">
         <input type="text" :class="Error == 1?'input-error':'input-1'" placeholder="Restaurant Name" v-model="restaurant" required>
         <h2 class="error-message" v-if="Error==1">{{ ErrorMessage }}</h2>
         <input type="text" :class="Error==2?'input-error':'input-1'" placeholder="Enter phone no." v-model="phone" required>
         <h2 class="error-message" v-if="Error==2">{{ ErrorMessage }}</h2>
         <input type="text" :class="Error==3?'input-error':'input-1'" placeholder="Enter email" v-model="email" required>
         <h2 class="error-message" v-if="Error==3">{{ ErrorMessage }}</h2>
         <address-field></address-field>
         <input type="password" :class="Error==4?'input-error':'input-1'" placeholder="Enter your password" v-model="password" required>
         <h2 class="error-message" v-if="Error==4">{{ ErrorMessage }}</h2>
         <input type="password" :class="Error==5?'input-error':'input-1'" placeholder="Confirm your password" v-model="confirm" required>
         <h2 class="error-message" v-if="Error==5">{{ ErrorMessage }}</h2>
        </div>
        <div id="alternate">
            Already a registered user?<span id="link" @click="redirect">Log in</span>
        </div>
        <submit-button Text="Sign Up" @click="submit"></submit-button>
    </div>
    </div>
    </template>
    
    <script>
    import BackButton from '../../general/back-button.vue'
    import SubmitButton from '../../general/submit-button.vue'
    import AddressField from '../signup/address-comp.vue'
    export default {
        components:{BackButton,SubmitButton,AddressField},
        data(){
            return{
                restaurant:'',
                phone:'',
                email:"",
                pinCode:"",
                password:"",
                confirm:"",
                errorField:'',
                errorMessage:""
            }
        },
        computed:{
            Error(){
                return this.errorField
            },
            ErrorMessage(){
                return this.errorMessage
            }
        },
        methods:{
            async submit(){
                let check_1 = await this.check1(this.phone)
                let check_2 = await this.check2(this.email)
                let check_3 = await this.check3(this.password,this.confirm)
                if(this.restaurant == ''){
                    this.errorField = 1
                    this.errorMessage = "This field is required"
            
                }
                else if(this.email == ''){
                     this.errorField = 2;
                     this.errorMessage = "This field is required"
                     
                }
                else if(this.phone == ''){
                    this.errorField = 3;
                    this.errorMessage = "This field is required"
                     
                }
                else if(this.password == ''){
                    this.errorField = 4;
                    this.errorMessage  = "This field is required"
                    
                }
                else if(this.confirm == ''){
                    this.errorField = 5;
                    this.errorMessage = 'This field is required'
                    
                }
                else if(!check_1){
                    this.errorField = 2;
                    this.errorMessage = 'Please enter a valid contact'
                    
                }
                else if(!check_2){
                    this.errorField = 3;
                    this.errorMessage = 'Please enter a valid email'
                    
                }
                else if(check_3 == 0){
                    this.errorField = 4;
                    this.errorMessage = "Password should contain at least 8 characters"
                    
                }
                else if(check_3==1){
                    this.errorField=5;
                    this.errorMessage = "Password do not match"
                    
                }
              else{
                let resStatus= await this.$store.dispatch('user/signUp',{
                    email:this.email,
                    restaurant_name:this.restaurant,
                    phone_no:this.phone,
                    password:this.password,
                    confirmPassword:this.confirm,
                    address:this.$store.getters['user/getAddress'],
                    zip_code:this.$store.getters['user/getZipCode']
                })
                if(resStatus==200){
                this.$store.commit('user/changeSignupPage',{value:1})
                return
                }
                if(resStatus==400){
                    this.errorField=1;
                    this.errorMessage='An outlet with these details already exists'
                }
                else{
                    this.errorField=1;
                    this.errorMessage='Invalid credentials'
                }
              }
            },
            async check1(value){ // phone number
               const phonePattern = /^[0-9]{10}$/; 
               return phonePattern.test(value);
            },
            async check2(value){ // email address
                const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                return emailPattern.test(value);
            },
            async check3(val1,val2){ // password matching and min length
               if(val1.length<8) {
                return 0
               }
               if(val1!=val2){
                return 1
               }
               return 2
            },
            redirect(){
                  this.$router.push({name:'login'})
            },
            back(){
                this.$router.push({name:'landing'})
            }
        }
    }
    </script>
    
    <style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
    #container{
        width:100vw;
        height: 100vh;
        overflow-x:hidden;
        overflow-y:auto ;
    
    }
    #container::-webkit-scrollbar{
        display: none;
    }
    #form{
        width:100vw;
        margin-top:6.5vh;
        background-color: white;
        height:93.5vh;
    
    }
    #sign-title{
        align-items: center;
        width:100vw;
        display: inline-flex;
        align-items: center; /* Vertical alignment */
  justify-content: center; /* Horizontal alignment */
    }
    #alternate{
        align-items: center;
        width:100vw;
        display: inline-flex;
        align-items: center; /* Vertical alignment */
  justify-content: center; /* Horizontal alignment */
    }
    @media only screen and (max-width: 480px){
    #container{
        background-image: url('~@/assets/images/mobile-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    #form{
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding-bottom:4vh;
        height: fill;
    }
    #back-button{
        margin-left:15px;
        margin-top:15px;
    }
    #sign-title h2{
        color: #413F3F;
        text-align: center;
        font-family: Inter;
        font-size: 27.99px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-left:4px;
        margin-right: 4px;
    }
    #sign-title{
        margin-top:20px;
    }
    #form-box{
        margin-top:25px;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:90vw;
        margin-left:5vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }

    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:90vw;
        margin-left:5vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
margin-bottom:21.52px;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #alternate{
        margin-top:20px;
        color: #0a0a0a;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #link{
        color: #007BFF;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:1px;
    }
    .error-message{
        font-family: Inter;
        font-size:14px;
        color:rgba(255, 0, 0, 0.601);
        font-weight: 400;
        margin-left:6.75vw;
        margin-top:-19px;
        margin-bottom:10px;
    }
    }
    @media only screen and (min-width:480px) and (max-width:960px){
    #container{
        background-image: url('~@/assets/images/desktop-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    #form{
        border-radius: 20px 20px 0px 0px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height:fill;
    padding-bottom:4vh;
    }
    #back-button{
        margin-left:30px;
        margin-top:20px;
    }
    #sign-title h2{
        color: #413F3F;
        text-align: center;
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-left:4px;
        margin-right: 4px;
    }
    #sign-title{
        margin-top:15px;
    }
    #form-box{
        margin-top:40px;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:60vw;
        margin-left:20vw;
        height:67px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

margin-bottom:21.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:60vw;
        margin-left:20vw;
        height:67px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
margin-bottom:21.52px;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #alternate{
        margin-top:20px;
        color: #0a0a0a;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #link{
        color: #007BFF;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:1px;
    }
    .error-message{
        font-family: Inter;
        font-size:15px;
        color:rgba(255, 0, 0, 0.601);
        font-weight: 400;
        margin-left:20.75vw;
        margin-top:-16px;
        margin-bottom:12px;
    }
    }
    @media only screen and (min-width:961px){
      #container{
        background-image: url('~@/assets/images/desktop-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
      #form{
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        height: fill;
        padding-bottom:4vh;
      }
      #back-button{
        margin-left:30px;
        margin-top:20px;
        cursor: pointer;
    }
    #sign-title h2{
        color: #413F3F;
        text-align: center;
        font-family: Inter;
        font-size: 23.99px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-left:4px;
        margin-right: 4px;
    }
    #sign-title{
        margin-top:5px;
    }
    #form-box{
        margin-top:35px;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:30vw;
        margin-left:35vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
margin-bottom:18.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:30vw;
        margin-left:35vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
margin-bottom:18.52px;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
    }
    #alternate{
        margin-top:10px;
        color: #0a0a0a;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #link{
        color: #007BFF;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-left:4px;
        cursor: pointer;
    }
    .error-message{
        font-family: Inter;
        font-size:14px;
        color:rgba(255, 0, 0, 0.601);
        font-weight: 400;
        margin-left:35.75vw;
        margin-top:-16px;
    }
    }
    </style>