import LoginPage from "../views/onboarding/Login-Page.vue";
import SignupPage from "../views/onboarding/signup-page.vue";
import ForgotPassword from "../views/onboarding/forgot-password.vue";
import LandingPage from "../views/onboarding/landing-page.vue";
const routes = [
  {
    name: "login",
    path: "/login",
    component: LoginPage,
  },
  {
    name: "signup",
    path: "/signup",
    component: SignupPage,
  },
  {
    name: "forgot",
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    name: "landing",
    path: "/landing",
    component: LandingPage,
  },
];

export default routes;
