export default {
  changeSignupPage(state, payload) {
    state.signupPage = payload.value;
  },
  changeForgotPassword(state, payload) {
    state.forgotPage = payload.value;
  },
  changeHomePage(state, payload) {
    state.homePage = payload.value;
  },
  setPaymentMode(state, payload) {
    state.paymentMode = payload.value;
  },
  setAddress(state, payload) {
    state.address = payload.value;
  },
  setCoordinates(state, payload) {
    state.latitude = payload.latitude;
    state.longitude = payload.longitude;
  },
  setNotification(state, payload) {
    state.notification = payload.value;
  },
  setNotificationData(state, payload) {
    state.notificationData = payload.value;
  },
  setZipCode(state, payload) {
    state.zipCode = payload.value;
  },
};
