<template>
    <div id="container">
        <div id="form">
            <back-button id="back-button" @click="back"></back-button>
            <div id="sign-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="74" height="5" viewBox="0 0 74 5" fill="none">
                    <path d="M73.0111 2.64648L70.7017 0.337083L68.3923 2.64648L70.7017 4.95589L73.0111 2.64648ZM70.7017 2.24648H0.527344V3.04648H70.7017V2.24648Z" fill="#25AD34"/>
                  </svg>
                  <h2>Card Details</h2>
                  <svg xmlns="http://www.w3.org/2000/svg" width="73" height="5" viewBox="0 0 73 5" fill="none">
                    <path d="M0.333177 2.64648L2.64258 4.95589L4.95198 2.64648L2.64258 0.337083L0.333177 2.64648ZM72.8169 2.24648H2.64258V3.04648H72.8169V2.24648Z" fill="#25AD34"/>
                  </svg>
            </div>
            <div id="form-box">
                <input type="text" class="input-1" placeholder="Card Number" v-model="cardNumber">
                <div id="row2">
                    <input type="text" class="input-2" placeholder="mm/yy" v-model="month">
                    <input type="text" class="input-2" placeholder="CVV" v-model="cvv">
                </div>
                <input type="text" class="input-1" placeholder="Country/Region" v-model="region">
            </div>
            <submit-button Text="Submit" @click="submit"></submit-button>
        </div>
    </div>
</template>

<script>
import BackButton from '../../general/back-button.vue'
import SubmitButton from '../../general/submit-button.vue'
export default {
    components:{BackButton,SubmitButton},
    data(){
        return{
            cardNumber:'',
            month:'',
            cvv:'',
            region:""

        }
    },
    methods:{
        back(){
            this.$store.commit('user/changeSignupPage',{value:0})
        },
        submit(){
            this.$store.commit('user/changeSignupPage',{value:2})
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

#container{
    width:100vw;
    height: 100vh;
    overflow-x:hidden;
    overflow-y:auto ;

}
#container::-webkit-scrollbar{
    display: none;
}
#form{
    width:100vw;
    margin-top:6.5vh;
    background-color: white;
    height:93.5vh;

}
#sign-title{
    align-items: center;
    width:100vw;
    display: inline-flex;
    align-items: center; /* Vertical alignment */
justify-content: center; /* Horizontal alignment */
}
@media only screen and (max-width:480px){
    #container{
        background-image: url('~@/assets/images/mobile-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    #form{
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    #back-button{
        margin-left:15px;
        margin-top:15px;
    }
    #sign-title h2{
        color: #413F3F;
        text-align: center;
        font-family: Inter;
        font-size: 27.99px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-left:4px;
        margin-right: 4px;
    }
    #sign-title{
        margin-top:100px;
    }
    #form-box{
        margin-top:45px;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:90vw;
        margin-left:5vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
margin-bottom:24.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder,
    .input-2::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
#row2{
    width:100vw;
    padding-left:5vw;
    display: grid;
    grid-template-columns: repeat(2,40vw);
    gap:9vw
}
.input-2,
    .input-2:focus,
    .input-2::selection{
        width:40vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
}
@media only screen and (min-width:480px) and (max-width:960px){
    #container{
        background-image: url('~@/assets/images/desktop-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    #form{
        border-radius: 20px 20px 0px 0px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    #back-button{
        margin-left:30px;
        margin-top:20px;
    }
    #sign-title h2{
        color: #413F3F;
        text-align: center;
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-left:4px;
        margin-right: 4px;
    }
    #sign-title{
        margin-top:100px;
    }
    #form-box{
        margin-top:40px;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:60vw;
        margin-left:20vw;
        height:67px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
margin-bottom:24.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #row2{
        width:100vw;
        padding-left:20vw;
        display: grid;
        grid-template-columns: repeat(2,25vw);
        gap:-2vw
    }
    .input-2,
        .input-2:focus,
        .input-2::selection{
            width:20vw;
            height:47px;
            border-radius: 30px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    margin-bottom:21.52px;
    border:none;
    outline:none;
    padding-left:20px;
    color: #181818;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
        }
}
@media only screen and (min-width:961px){
    #container{
        background-image: url('~@/assets/images/desktop-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
      #form{
        border-radius: 20px 20px 0px 0px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
      #back-button{
        margin-left:30px;
        margin-top:20px;
        cursor: pointer;
    }
    #sign-title h2{
        color: #413F3F;
text-align: center;
font-family: Inter;
font-size: 23.99px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:4px;
        margin-right: 4px;
    }
    #sign-title{
        margin-top:5px;
    }
    #form-box{
        margin-top:100px;
    }
    .input-1,
    .input-1:focus,
    .input-1::selection{
        width:30vw;
        margin-left:35vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
margin-bottom:26.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    #row2{
        width:100vw;
        padding-left:35vw;
        display: grid;
        grid-template-columns: repeat(2,10vw);
        gap:2vw
    }
    .input-2,
        .input-2:focus,
        .input-2::selection{
            width:10vw;
            height:47px;
            border-radius: 30px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    margin-bottom:21.52px;
    border:none;
    outline:none;
    padding-left:18px;
    color: #181818;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
        }
}
</style>